@layer modules, ui, base;
@layer base {
  /**
 * FilterBar component is used to wrap Filters and Select. As it override some styles on children, It suffers same issue
 * as Select (src/modules/uiKitOverrides/SelectFilter/Select.module.css), and need some !important in nextjs context.
 */

.FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
  margin: 0 0 -1px;
}

.FilterBar_filterBar__SW2X9 [class*="jds-Select__control"] {
  min-height: 48px !important;
}

@media (min-width: 48rem) {
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
    margin: 0 -1px -1px 0;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Input"] {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Input"] {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Input"] {
    border-radius: 0 !important;
  }
}

}
@layer ui {
  /** @define Pagination */

.pagination_jds-Pagination__5KFrR {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
}

.pagination_jds-Pagination__wrapper__wxN4Q {
  margin: var(--spacer-400) 0 var(--spacer-800) 0;
}

.pagination_jds-Pagination__item__55Rn3 {
  display: flex;
  align-items: center;
  margin-right: var(--spacer-200);
  text-align: center;
}

.pagination_jds-Pagination__item__55Rn3:last-child {
  margin-right: 0;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  background-color: transparent !important;
  color: var(--color-black) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw:hover {
  border-color: transparent;
  background-color: var(--color-grey--100) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART,
.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  min-width: 40px;
  border-color: transparent !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART {
  color: var(--themeButtonTextColor, var(--color-black)) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button--disabled__e4_45 {
  color: var(--color-grey--200) !important;
}

}
